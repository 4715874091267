@import 'Colours';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Frutiger, Myriad, sans-serif !important;
}

:root {
  --nav-bar-bg-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

#main-cont {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Removing outlines for focus containers */
#main-cont, #main, #footer, #card-main, #sidebar, #trans-main, #error-alert{
  outline: none;
}

#main {
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  position: relative;
}

.fa-spin.fastSpin {
  -webkit-animation: fa-spin 0.5s infinite linear;
  -moz-animation: fa-spin 0.5s infinite linear;
  animation: fa-spin 0.5s infinite linear;
}

a {
  color: inherit;
  text-decoration: none !important;
}

.visuallyHidden {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.cont {
  max-width: 1800px;
  @media (max-width: 768px) {
    max-width: 1400px;
  }
  @media (max-width: 576px) {
    max-width: 800px;
  }
}

.cardImgDropShadow {
  filter: drop-shadow(-4px 4px 4px rgba(255, 255, 255, 0.2));
}

.invisibleBtn {
  background-color: transparent;
  border: none;
}

.mapleLink {
  font-weight: 600;
  color: $link;
  &:hover {
    color: $link-hover;
  }
}

button.mapleLink {
  background: none;
  border: none;
  padding: 0;
}

.smallLink {
  @extend .mapleLink;
  font-size: 80%;
}

.inputHelpLink {
  @extend .mapleLink;
  @extend .invisibleBtn;
  font-size: 80%;
}

.mapleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.55em 1.7em;
  margin: 0.6em 0.6em 0.6em 0;
  min-width: 1.75em;
  min-height: 3em;
  border-radius: 12px;
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
  transition: background-color 0.1s, color 0.1s, border-color 0.1s;
  border: 0.2rem solid var(--btn-primary);
  &[disabled] {
    pointer-events: none;
  }

  &.disable {
    opacity: 0.7;
  }

  &.dark {
    --btn-primary: #{$maple-dark-blue};
    --btn-secondary: white;
    --btn-hover: #{$button-primary-active};
  }
  &.white {
    --btn-primary: white;
    --btn-secondary: #{$maple-dark-blue};
    --btn-hover: #eeeeee;
  }

  &.solid {
    background: var(--btn-primary);
    color: var(--btn-secondary);
    &.disable:hover {
      background: var(--btn-primary);
      color: var(--btn-secondary);
      border-color: var(--btn-primary);
    }
    &:hover {
      background-color: var(--btn-hover);
      border-color: var(--btn-hover);
    }
  }
  &.outline {
    background: transparent;
    color: var(--btn-primary);
    &.disable:hover {
      background: transparent;
      color: var(--btn-primary);
      border-color: var(--btn-primary);
    }
    &:hover {
      background-color: var(--btn-primary);
      color: var(--btn-secondary);
    }
  }

  &.small {
    padding: 0.1em .5rem;
    font-size: .875rem;
    line-height: 1.4;
    min-height: 2em;
  }

  &.full {
    width: 100%;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(24 26 75 / 25%);
  }
}

.linkBtn {
  background: none !important;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  color: $link !important;
  font-weight: 500;
  min-width: auto;
  padding: 5px 0;

  &:focus-visible {
    outline: 2px solid black;
    border-radius: 3px;
  }
  &:hover {
    text-decoration: underline !important;
  }
}

.googleRecaptchaDisclaimer {
  font-size: 0.7rem;
  margin-bottom: 20px;
}

.fw-600 {
  font-weight: 600;
}

.alert {
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  &.full {
    width: 100%;
  }

  p {
    margin-bottom: 0;
  }
  
  .sub {
    margin-top: 0.2rem;
    font-size: 90%;
    line-height: 1.2;
  }
}

.alert.error {
  border: 3px solid $state-danger;
  margin-bottom: 1rem;
}

.alert.success {
  border: 3px solid $state-success;
  margin-bottom: 1rem;
}

.textSuccess {
  color: $state-success;
}
.bgSuccess {
  background-color: $state-success;
}
.textDanger {
  color: $state-danger;
  margin: 0 !important
}
.flexWrap {
  flex-wrap: wrap !important;
}
.bgDanger {
  background-color: $state-danger;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexRow.spaceBetween {
  justify-content: space-between;
}

.errorPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 20px;
  min-height: 300px;

  @media only screen and (max-width: 768px) {
    padding: 40px 20px;
  }
  h1 {
    text-align: center;
    max-width: 700px;
  }
}

.cardBackImg {
  max-width: 300px;
}

.emailResendWaitTxt {
  line-height: 1.2rem;
  font-size: 90%;
}

.banner-cont {
  text-align: center;
  background-color: #004a8e;
  color: white;
  width: 100%;
  padding: 10px 3rem;
  position: relative;
    
  a {
    text-decoration: underline !important;
    &:hover {
      color: #dddddd;
    }
  }

  b {
    font-weight: 600;
  }

  .dismissBtn {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    &:hover svg {
      opacity: 1;
    }
    &:focus {
      outline: auto 1px;
    }

    svg {
      color: white;
      opacity: 0.75;
    }
  }
}

// Help Centre widget button
// necessary because we are not using Font Awesome Kit for Maple
#widget-button i {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 0.95em !important;
  margin: auto !important;
  width: 0.75em;
  height: 1em;
  content: url('../public/assets/images/question-solid.svg')
}

.printBtn {
  padding: 0.4rem 0.8rem;
  height: auto;
  min-width: auto;
  svg {
    margin-right: 0.5rem;
  }
}

.printOnly {
  display: none;
}

@media only print {

  html, body {
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  nav, .skipCont, #widget-button {
    display: none !important;
  }
  .legalDoc {
    footer, .legalDocHeaderCont {
      display: none !important;
    }
  }
  .printingTrans {
    page: transactions;

    #__next {
      display: none;
    }

    .printOnly {
      display: block;
      font-size: medium;

      h1 {
        font-size: 1.5rem;
      }

      table {
        font-family: monospace;
        width: 100vw;
        & th, & td {
          border: 1px solid black;
          border-collapse: collapse;
          padding-left: 0.5ch;
          padding-right: 0.5ch;
        }
        tr {
          break-inside: avoid;
        }
        td {
          p{
            margin-top: 15px;
            margin-bottom: 0px;
            &:first-child {
              margin-top: 0px !important;
            }
          }
          &:nth-child(3) {
            white-space: nowrap;
            text-align: right;
          }
          &:nth-child(4) {
            text-align: right;
          }
        }
      }

      &.noForeignTrans table {
        td:nth-child(4), th:nth-child(4) {
          display: none;
        }
      }
    }
  }

  .printingVoidCheque {
    page: voidCheque;

    #__next {
      display: none;
    }

    .printOnly {
      display: block;
      font-size: medium;

      .title{
        margin-bottom: 14px;
        font-size: 23.3pt;
        font-weight: normal;
      }
      
      #disclaimer, #disclaimer + p {
        font-size: inherit;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }

      .sectionTitle{
        font-size: 17pt;
        margin-bottom: 0px;
        font-weight: normal;
      }
      
      .row {
        display: flex;
        flex-direction: row;
        column-gap: 10%;
        --bs-gutter-x: 0;
      }
      
      .field-s{
        width: 32%;
        padding-top: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
      
      .label {
        margin-bottom: 10px;
      }
      
      .value {
        margin-bottom: 10px;
      }
      
      .subsection{
        font-weight: normal;
        font-size: 15pt;
        margin-top: 22px;
      }
      
      .field-l{
        width: 74%;
        margin-top: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
      
      .divider-item{
        margin-top: 30px;
        margin-bottom: 27px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
      
      ul {
        font-style: italic;
        margin-top: 10px;
      }
      
      .blank {
        height: 15px
      }

      .field-s, .field-l {
        break-inside: avoid;
      }
    }
  }
}

@page transactions {
  size: landscape;
}

@page transactions {
  size: portrait;
}


// Bootstrap error color override
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: $state-danger !important;
}

.invalid-feedback {
  color: $state-danger !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: $state-danger !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23db3242'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23db3242' stroke='none'/%3e%3c/svg%3e") !important;
}

// Fields need to occupy the whole div in the Direct Deposit page
// that's why 100% width is necessary.
.copyInfoFieldContainer {
  width: 100%;
}

.copyInfoField {
  border: 0;
  border-bottom: 0.4px solid #3333336b;
  width: 100%;
  margin-bottom: 20px;

  .copyInfoFieldValueContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      color: rgba(0, 0, 0, 0.8);
      word-break: break-all;
      text-align: left;
      font-size: 20px;
    }
    margin-bottom: 10px;
  }
  
  // The min-width is necessary to accommodate the text 'Copied' on smaller screens. 
  .copyTextContainer {
    min-width: 60px;
    margin-right: 6px;

    .copyText {
      height: 1.33333em;
      line-height: 1.5;
      color: $maple-dark-blue !important;
      font-weight: 600;
      text-align: end !important;
      font-size: 16px !important;
    }
  }
}

.copyButtonStyle {
  background-color: transparent;
  padding: 0;
}