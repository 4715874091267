@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 45 Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 46 Light Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 55 Roman.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 56 Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 65 Bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 66 Bold Italic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 75 Black.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Frutiger;
  src: url('Frutiger LT 76 Black Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
