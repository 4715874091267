@import "Colours";

.myCardBg {
  background-color: #f8f8f8;
}


.myCardCPLogo {
  display: inline-block;
  cursor: pointer;
  margin: 15px 0 5px 45px;
  display: block;
  aspect-ratio: 62 / 15;
  width: 125px;
  min-width: 60px;
  position: relative;
}

.linkCardIcon {
  font-size: 5rem;
  opacity: 0.5;
}

.linkCardTitle {
  font-size: 1.9rem;
  margin-top: 20px;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 1.7rem;
  }
}

.linkCardContainerPrompt {
  margin: 2rem auto 4rem auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 0 5px;
    margin: 0rem 0 3rem 0;
  }

  @media screen and (max-width: 450px) {
    margin: 2rem 0 3rem 0;
  }
}

.linkCardContainerForm {
  margin: calc(125px + 1rem) auto 3rem auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 0 5px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 1rem;
  }
}

.linkCardOptionsDiv {
  --bs-gutter-x: 0;
  margin-top: 3rem !important;

  @media screen and (max-width: 767px) {
    margin-top: 1rem !important;
  }
}

.linkCardOptionDiv {
  background-color: white;
  border-radius: 30px;
  border: 1px solid rgb(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
  min-width: 350px;

  h2 {
    font-size: 1.375rem;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    margin: 20px 0;
    min-width: auto;
  }
}

.linkCardForm {
  background-color: white;
  padding: 3rem 2rem 1rem 2rem;
  border-radius: 30px;
  filter: drop-shadow(0px 10px 10px rgb(0, 0, 0, 0.5));
  max-width: 37.5rem;
  @media screen and (max-width: 525px) {
    padding: 1.5rem;
  }
}

.linkCardCancel {
  margin-top: 20px;
  margin-left: 25px;
  position: absolute;
  top: 0;
  left: 0;

  button {
    color: $maple-dark-blue;
    line-height: normal;
  }
}

.linkCardSubtitle {
  opacity: 0.8;
  margin-bottom: 10px;
}

.linkCardImg {
  position: absolute;
  top: -125px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  @media screen and (max-width: 450px) {
    display: none;
  }
}

.linkCardForm > .formContainer {
  margin-top: 3rem;

  @media screen and (max-width: 525px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 450px) {
    margin-top: 3rem;
  }
}

.myCardContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.myCardSidebarContainer {
  position: fixed;
  height: 100%;
  padding-top: 57px;
}

.myCardSidebarLogoContainer {
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

#card-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.myCardContent {
  padding: 30px;
  width: 100%;
  margin-top: 3rem;
}

.myCardContent,
footer.myCard .footer-bottom {
  margin-left: 14em;
}

.myCardSidebar {
  background-color: $maple-dark-blue;
  color: white;
  width: 14em;
  border-top-right-radius: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cardContainer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myCardCardImg {
  width: 9.375rem;
}

.changeCard {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.changeCardLink {
  color: white !important;
  font-size: 80%;
}

.cardName {
  font-size: 90%;
  margin-bottom: 0;
}

.myCardSidebarItem:hover {
  background-color: $button-primary-active !important;
}

.myCardSidebarItem:focus {
  background-color: $button-primary-active !important;
}

.myCardSidebarItem {
  background: none;
  color: inherit;
  border: none;
  padding: 10px 10px 10px 20px !important;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 15px auto;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;

  .sidebarDropdownChevron {
    margin-left: auto;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0;
    margin-left: 15px;
    text-align: left;
  }

  .icon {
    min-width: 30px;
  }

  &.active {
    background-color: #f8f8f8;
    color: $maple-dark-blue;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  &.active:hover {
    background-color: white !important;
  }

  &.active:focus {
    background-color: white !important;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
  }
}

.myCardSidebarItemLink {
  @extend .myCardSidebarItem;
  margin: 0 !important;
  color: white !important;
  font-weight: 500 !important;
}

.homeCardLegalLink {
  margin: 0 !important;
}

.myCardSidebarItemLink {
  &:hover, &:focus {
    @extend .myCardSidebarItem;
    margin: 0;
    color: white !important;
    font-weight: 500 !important;
    border-radius: 0 !important;
  }
  &:first-child {
    &:hover, &:focus {
      @extend .myCardSidebarItem;
      border-top-right-radius: 10px !important;
    }
  }
  &:last-child {
    &:hover, &:focus {
      @extend .myCardSidebarItem;
      border-bottom-right-radius: 10px !important;
    }
  }
}

.myCardSidebarItemDropdown {
  @extend .myCardSidebarItem;
}

#sidebar-dropbtn {
  @extend .myCardSidebarItem;
  box-shadow: none;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  &:after {
    display: none !important; 
  }
}

.myCardSidebarItemDropdownCont {
  padding: 0 !important;
  border-style: none !important;
  border-radius: 0 !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  transition: opacity 0.2s;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

.sidebarItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
  padding-bottom: 10%;
}

.cardInfoItem {
  padding: 10px 20px 0 20px;

  &.balanceItem {
    background-color: rgb(255 255 255 / 15%);
    color: #ffffff;
  }

  &.cardStatusItem {
    padding: 5px 20px 5px 10px;
    background-color: #ffffff;
    border-color: #d41811;
    border-style: solid;
    border-width: 0 0 0 8px;
    color: #000000;
    margin-bottom: 10px;
    margin-right: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &.cardStatusA {
      border-color: #028a00;
      .cardStatus {
        color: #028a00;
      }
    }
  }

  .cardInfoUpper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    margin-bottom: 0;
  }

  .cardInfoTitle {
    font-size: 0.9rem;
    margin-bottom: 0;
    opacity: 0.9;
  }

  .cardInfoValue {
    &.balance {
      font-size: 1.8rem;
    }

    &.cardStatus {
      font-size: 1rem;
      color: #d41811;
      font-weight: 600;
    }
  }
}

.cardInfoContainer {
  margin: 20px 0 5px;

  @media screen and (max-width: 600px) {
    margin: 10px 0;
  }
}

.homeCardFeaturesTitle {
  font-size: 1.8rem;
  margin-bottom: 20px;
  @media screen and (max-width: 767.9px) {
    margin-top: 30px;
  }
}

.homeCardFeatures {
  border-radius: 20px;
  margin-bottom: 30px;

  .homeCardFeatureTitle {
    font-size: 1.3rem;
    margin-bottom: 5px;
  }

  .homeCardFeatureSubtitle {
    opacity: 0.95;
  }
}

.homeCardFeature {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #fafafa;
    color: inherit;
  }
}

.homeCardIcon {
  opacity: 0.8;
}

.homeCard {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 15px 20px 8px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .homeCardTitle {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem !important;
  }

  .balanceValue {
    font-size: 2.5rem;
    margin-bottom: 0;
  }

  .infoItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
    }

    .lbl {
      margin-right: 10px;
      opacity: 0.9;
    }

    .val {
      font-weight: 600;
      word-break: break-all;
    }
  }
}

.homeGoogleApp {
  width: 150px !important;
  padding-bottom: 8px;
  padding-right: 0 !important;
}

.homeAppleApp {
  width: 150px !important;
  padding-right: 0 !important;
}

.homeCardInfo {
  .cardStatusItem {
    margin-bottom: 30px;
    margin-right: 0;
    border-right-color: rgba(0, 0, 0, 0.2);
    border-right-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-bottom-width: 1px;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-top-width: 1px;
  }

  .cardInfoTitle {
    font-size: 1rem;
  }

  .cardInfoValue.cardStatus {
    font-size: 1.3rem;
  }
}

.homeCardInfoContainer {
  max-width: 550px !important;
}

.homeCardFeaturesContainer {
  margin-left: 50px;
  max-width: 700px !important;
  @media screen and (max-width: 1475px) {
    margin-left: 0;
  }
}

.homeCardLegalLinkList {
  padding: 0;
  text-indent: -0.8rem;
  padding-left: 1em;
}

.homeCardLegalLink {
  list-style: none;
  padding-bottom: 5px;
  padding-left: 10px;
  color: $maple-dark-blue;

  a {
    padding-left: 5px;
  }

  .homeCardLegalLinkIcon {
    font-size: 0.5em;
    padding-bottom: 3px;
    vertical-align: middle;
  }
}

@media screen and (max-width: 600px) {
  .homeCardInfoContainer {
    max-width: 100% !important;
  }
  .myCardContent {
    padding: 30px 20px 20% 20px;
    margin-left: 0;
    margin-top: 0;
    // To avoid content being obscured by the HelpCentre button and action bar at the bottom.
    margin-bottom: 70px;
  }

  .homeCardFeaturesTitle {
    font-size: 1.5rem;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  /* Mobile view of sidebar */
  .myCardSidebarContainer {
    height: auto;
    position: relative;
    padding-top: 0;
  }

  .sidebarItems {
    position: fixed;
    bottom: 0;
    background-color: $maple-dark-blue;
    width: 100%;
    z-index: 1;
    padding-bottom: 0;
  }

  .cardContainer,
  .lowerSidebarItems,
  .myCardCPLogo {
    display: none !important;
  }
  .myCardSidebar {
    width: 100%;
    border-radius: 0;
  }
  .myCardContainer {
    height: auto;
    flex-direction: column;
  }
  .upperSidebarItems {
    display: flex;
  }
  .myCardSidebarItem:hover {
    border-radius: 10px;
  }
  .myCardSidebarItem:focus {
    border-radius: 10px;
  }
  .myCardSidebarItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0 !important;
    margin: 5px 0;

    &.active {
      border-radius: 10px;
    }

    p {
      text-align: center;
      margin-left: 0;
      font-size: 60%;
    }
  }

  body.myCard {
    footer .footer-bottom {
      display: none;
    }
    nav .footer-bottom {
      background-color: hsl(0, 0%, 90%);

      p {
        color: black;
        padding: 0.5em;
        font-weight: normal;
      }
    }

    // Help Centre widget button
    #widget-button {
      bottom: 70px !important;
      right: 25px !important;
    }
  }

  #MyCardAnnouncement {
    display: none;
  }
}

.myCardFeaturePageTitle {
  font-size: 2rem;
  margin-bottom: 20px;
}

.myCardFeaturePageCont {
  max-width: 40rem;
}

.myCardFeaturePageContDirectDeposit {
  max-width: 40.625rem;
}

.myCardFeaturePageContDirectDepositInfo {
  max-width: 45rem;
  .myCardFeaturePageTitle{
    margin-bottom: 0px
  }
}

#trans-main {
  container-type: inline-size; // needed for container queries

  .myCardFeaturePageTitle {
    margin-bottom: 10px;
  }

  .sixMonthsButton {
    color: $link;
    border-color: transparent !important;
    background-color: transparent !important;
    height: auto;
    min-height: auto;
    padding: 0;
    margin: 1px 0 0 0;
    font-size: 0.9rem;
    border-radius: 0;
    border: none;
    padding-block: 2px;
    text-align: left;
    &:hover, &:focus {
      color: $link-hover;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

// container query in for UI in English
@container (width < calc(2.775rem + (13ch + 3rem) * 2 + (6ch + 2.4rem) + 15px)) {
  html[lang="en"] .dateRange {
    grid-template-columns: var(--date-input-width) 1fr;
    .formField:first-child { // calendar icon button
      grid-area: 1 / 1 / 2 / 3;
    }
    .sixMonthsButton {
      grid-area: 3 / 1 / 4 / 3;
    }
    #DateRangeError {
      grid-area: 4 / 1 / 5 / 3;
    }
    #SearchDateRangeBtn {
      grid-area: 5 / 1 / 6 / 3;
      width: auto;
      justify-self: stretch;
    }
  }
}
// container query in for UI in French
@container (width < calc(2.775rem + (13ch + 3rem) * 2 + (9ch + 2.4rem) + 15px)) {
  html[lang="fr"] .dateRange {
    grid-template-columns: var(--date-input-width) 1fr;
    .formField:first-child { // calendar icon button
      grid-area: 1 / 1 / 2 / 3;
    }
    .sixMonthsButton {
      grid-area: 3 / 1 / 4 / 3;
    }
    #DateRangeError {
      grid-area: 4 / 1 / 5 / 3;
    }
    #SearchDateRangeBtn {
      grid-area: 5 / 1 / 6 / 3;
      width: auto;
      justify-self: stretch;
    }
  }
}
// container query for when the two date fields next to each other can't fit
@container (width < calc((13ch + 3rem) * 2 + 5px)) {
  html[lang="en"], html[lang="fr"] {
    .dateRange {
      grid-template-columns: 1fr;
      .formField:first-child { // calendar icon button
        grid-area: 1 / 1 / 2 / 2;
      }
      .formField:nth-child(2) { // start date input
        grid-area: 2 / 1 / 3 / 2;
      }
      .sixMonthsButton {
        grid-area: 3 / 1 / 4 / 2;
      }
      .formField:nth-child(3) { // end date input
        grid-area: 4 / 1 / 5 / 2;
      }
      #DateRangeError {
        grid-area: 5 / 1 / 6 / 2;
      }
      #SearchDateRangeBtn {
        grid-area: 6 / 1 / 7 / 2;
      }
    }
  }
}

.dateRange {
  --search-btn-width: 6ch; // update container query when changing this
  html[lang="fr"] & {
    --search-btn-width: 9ch; // update container query when changing this
  }
  --date-input-width: calc(13ch + 3rem); // update container query when changing this
  margin-top: -5px;
  position: relative;
  display: grid;
  grid-template-columns: 2.775rem var(--date-input-width) var(--date-input-width) calc(var(--search-btn-width) + 2.4rem);
  column-gap: 5px; // update container query when changing this
  justify-items: start;
  align-items: end;

  .sixMonthsButton {
    grid-area: 2 / 2 / 3 / 5;
  }
  #DateRangeError {
    grid-area: 3 / 2 / 4 / 5;
  }

  .formField {
    margin-top: 0;
    display: inline-flex;
    flex-direction: column;
    vertical-align: bottom;

    label {
      margin-left: 3px;
      margin-bottom: -5px;
      .formatLbl {
        margin-top: -3px;
      }
    }
  }

  input {
    display: inline;
    width: var(--date-input-width);
  }

  .react-datepicker__tab-loop,
  .react-datepicker__tab-loop__start,
  .react-datepicker__tab-loop__end {
    display: inline;
  }

  .react-datepicker-wrapper {
    visibility: hidden;
    position: absolute;
    box-sizing: content-box;
    width: 0.875rem;
    margin-top: 5px;
  }

  .react-datepicker__today-button {
    display: none;
    width: 242px;
    border-radius: 0 0 0.3rem 0.3rem;
  }

  &.enableTodayBtn {
    .react-datepicker__today-button {
      display: block;
    }
  }

  button.dateRangePicker {
    display: inline-flex;
  }
  .dateRangePicker {
    border-radius: 8px;
    border-style: solid;
    height: auto;
    min-height: auto;
    min-width: auto;
    padding: 0.535rem 0.75rem; // update container query when changing the inline padding
    margin: 0;
    margin-top: 5px;
    font-size: 1rem; // update container query when changing this
    line-height: 1.5;
    &.react-datepicker-wrapper {
      margin: calc(5px + 0.2rem) 0.2rem 0.2rem 0.2rem;
      height: 1rem;
    }
  }

  #DateRangeError {
    display: block;
    color: #da2b3c;
    margin-top: 0;
  }

  #SearchDateRangeBtn {
    width: var(--search-btn-width);
  }
}

#SearchDateRangeBtn, .saveTransBtn {
  display: inline-flex;
  vertical-align: bottom;
  padding: 0.295rem 1rem;
  margin: 5px 0 0 0;
  box-sizing: content-box;
  border-radius: 8px;
  min-height: 1.5rem;
  svg {
    font-size: 1.2rem;
  }
}

.tooltipBtnCont {
  display: inline-block;
  position: relative;
}

.saveTransBtn {
  margin-top: 5px;
  &:first-child {
    margin-right: 5px;
  }
}

.transactionListContainer {
  max-width: 37.5rem;
  margin-top: 10px;

  .transactionInfoContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    column-gap: 10px;

    #NumberOfDays {
      margin-bottom: 5px;
      margin-right: 10px;
      font-size: small;
      opacity: 0.8;
    }
  
    .detailsDisclaimer {
      margin-bottom: 5px;
      margin-left: 10px;
      font-size: small;
      opacity: 0.8;
      flex: 1;
    }
  }
  
}

.transactionRespContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.transactionList {
  list-style: none;
  padding: 0;
}



.saveAsFileBtn {
  margin-top: 0% !important;
}

.transactionContainer {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-top: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  position: relative;
  line-height: 1.5;
  transform: scaleY(1);
  transition: transform 1s ease 0ms;
  width: 100%;

  @media screen and (max-width: 450px) {
    padding-bottom: 10px;
  }

  &.transactionContainerBottom {
    padding-bottom: 15px;
  }

  &:hover {
    background-color: #fcfcfc;
  }

  .transRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    // For smaller screens, show all the info column wise to
    // avoid longer strings overflowing. 
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  .transLeft {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .transRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    @media screen and (max-width: 450px) {
      align-items: flex-start;
    }
  }

  .description {
    font-size: 1.1rem;
    text-align: left;
  }

  .expandedRow {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .merchant {
    font-size: 0.9rem;
  }

  .extraText {
    font-size: 0.8rem;
  }

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .datetime {
    opacity: 0.9;
    font-size: 0.8rem;
  }

  .amount {
    font-size: 1.3rem;
  }

  .bottomRow {
    font-size: 0.8em;
    opacity: 0.7;
  }

  .pending {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;

    svg {
      margin-right: 0.1rem;
      margin-bottom: 0.17rem;
    }

    span {
      line-height: 1.2;
    }
  }
}

.transactionNavContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  column-gap: min(60px, calc((100% - 120px - 4rem)/2));

  .mapleBtn {
    padding: 0 20px;
    height: 40px;
    min-height: auto;
    margin: 0;
  }

  #PageNum {
    width: 4rem;
    svg {
      font-size: 1.5rem;
    }
  }
}

.loadFundsBlocked {
  max-width: 40.625rem;
}

.loadFundsContainer {
  background-color: white;
  border-radius: 20px;
  filter: drop-shadow(0px 10px 10px rgb(0, 0, 0, 0.5));
  max-width: 45rem;
  padding-top: 1.6rem;

  .dailyLimitTitle {
    font-size: 1.3rem;
    text-align: center;
    padding: 0.4rem 15px 1rem;
  }

  .alert {
    margin: 0 2rem 1.1rem !important;
    width: calc(100% - 4rem);
  }
}

.eTransferSentCard {
  @extend  .loadFundsContainer;
  padding: 1.6rem;
  max-width: 45rem;

  .cardTitle {
    font-size: 1.3rem;
  }
}

.loadFundsLineLbl,
.loadAmtLbl {
  font-size: 1.2rem;
}

.loadAmtDisclaimer {
  opacity: 0.8;
  font-size: 90%;
  display: block;
}

.loadFundsInner {
  padding: 1rem 2rem;
  .notice {
    margin-bottom: 0.5rem;
  }
}

.loadFundsLineItem {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 2rem;

  &.important {
    span {
      font-weight: 600;
    }
  }

  .loadFundsLineVal {
    font-size: 1.3rem;
    margin-right: 12px;
  }
}

.orDiv {
  max-width: 45rem;

  .or {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
    text-align: center;
  }
}

.loadFundsCanadaPostContainer {
  background-color: white;
  border-radius: 20px;
  filter: drop-shadow(0px 10px 10px rgb(0, 0, 0, 0.5));
  max-width: 45rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;

  .loadFundLine {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
  }
}

.loadFundNearestPost {
  --btn-height: 3.1em;
  min-height: var(--btn-height);
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 12px;
  display: flex;
  align-items: center;
  position: relative;

  #SquareLogoCont {
    position: absolute;
    left: -1px;
    border-radius: 12px;
    overflow: hidden;
    height: var(--btn-height);
    width: var(--btn-height);
  }

  .findLocationText {
    display: inline-block;
    margin-left: calc(var(--btn-height) + 8px);
  }
}

.reloadAmountContainer {
  padding: 0.5rem 2rem;
}
.interacRequestSent{
  &Container{
    padding: 0.5rem 2rem;
  }
  &Header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  &Title {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 1.5rem;
  }
}

#CardManagementFeatures {
  .homeCardFeatureTitle {
    margin-bottom: 0;
    svg {
      margin-right: 10px;
      color: $maple-dark-blue;
    }
  }
  .homeCardIcon {
    display: flex;
    svg {
      font-size: 1.5em;
      height: 1.5em;
    }
  }
}
.myCardBack {
  height: 2em;
  min-height: auto;
  width: fit-content;
  padding: 0.8em 1.5em;
  margin-top: 0;
  margin-bottom: 1.5rem;
  svg {
    margin-right: 5px;
  }
}
.cardManagementActionContainer {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 20px;
  border-radius: 20px;
  max-width: 800px;
  p {
    margin-bottom: 0;
    font-weight: 600;
  }
}
.cardManagementActionTitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.cardManagementActionBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.inputFieldRow {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  @media screen and (max-width: 753px) {
    display: block;
  }
}

.emptyDiv {
  width: 100%;
}

.inputField {
  display: flex;
  flex-direction: row;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.directDepositAddressTitle {
  font-size: 1.25rem;
  margin-bottom: 5px;
}

.myCardSettingsContainer {
  max-width: 37.5rem;
}

.myCardSettingsInner {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 20px;
  border-radius: 20px;
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  .rightSide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .personalInfoInputTop {
    margin-top: 15px;
    margin-bottom: 18px;
  }

  .personalInfoInputBottom {
    margin-top: 15px;
  }

  .settingsItemTitle {
    font-size: 1.5rem;
    padding-bottom: 0;
  }

  .settingsItemBodyTitle {
    font-size: 1.25rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .accountholderTitle {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .bankInfoTitle {
    font-size: 1.25rem;
    font-weight: 500;
    padding-bottom: 20px;
  }

  .settingsItemSubtitle {
    font-size: 90%;
    opacity: 0.8;
    margin-bottom: 0;
  }

  .settingsItemBody {
    font-size: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .personalInfoColumn {
    display: flex;
    flex-direction: column;
    p{margin-bottom: 0%;}
  }
 
   .personalInfoRow {
     display: flex;
     flex-direction: row;
     column-gap: 9.6%;
  }
 
    .personalInfoItemSubtitle {
     opacity: 0.9;
     width: 115px;
  }
}

#CardReplacementReason {
  .form-check {
    font-size: 1.1rem;
    margin-block: 0.7rem;
  }
  label {
    vertical-align: middle;
    font-size: 1rem;
  }
  span {
    display: none;
  }
  .form-check.is-invalid + span {
    display: block;
  }
}
#CardReplacementTerms, #CardReplacementSuccess {
  p {
    white-space: pre-line;
    line-height: 1.2;
    b {
      font-weight: 600;
    }
  }
}

#Unblock {
  button {
    margin: 1.2rem 0 0.2rem 0;
    
  }
}

.peronalInfoButtonGroup{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.directDepositContainer {
  .directDepositTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    button{
      line-height: 1;
      height: 1.6em;
      min-height: 1.6em;
      margin-top: 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-bottom: 0;
    }

    & + p {
      margin: 0;
    }
  }

  .directDepositButtonGroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }


  .personalInfoContainer{
    padding-top: 20px;
    padding-bottom: 40px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto minmax(50%, 1fr);
    gap: 1.6em 25px;

    p {
      margin-bottom: 0;
      margin-top: 0;
    }

    & > *:nth-child(odd) {
      opacity: 0.8;
    }
    & > *:nth-child(even) {
      font-weight: 600;
      p {
        padding-bottom: 2px;
      }
    }
  }
}

#MyCardAnnouncement {
  position: sticky;
  top: 0;
  z-index: 10;
}

#Alerts {
  --switch-width: 2.5em;
  --switch-ratio: 0.55;
  --switch-inner-gap: 3px;
  --switch-height: calc(var(--switch-width) * var(--switch-ratio));
  --slider-diameter: calc(var(--switch-height) - var(--switch-inner-gap) * 2);

  .cardholderAlert {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 10px;
    cursor: not-allowed;
    &:last-child {
      margin-bottom: 0;
    }

    label {
      cursor: inherit;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    width: var(--switch-width);
    height: var(--switch-height);

    &:checked ~ .switch .slider {
      background-color: $maple-dark-blue;
      &:before {
        -webkit-transform: translateX(
          calc(
            var(--switch-width) - var(--switch-inner-gap) * 2 -
              var(--slider-diameter)
          )
        );
        -ms-transform: translateX(
          calc(
            var(--switch-width) - var(--switch-inner-gap) * 2 -
              var(--slider-diameter)
          )
        );
        transform: translateX(
          calc(
            var(--switch-width) - var(--switch-inner-gap) * 2 -
              var(--slider-diameter)
          )
        );
      }
    }
    &:disabled ~ .switch {
      opacity: 0.7;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: var(--switch-width);
    height: var(--switch-height);
    margin-right: 8px;

    .slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: var(--switch-height);
      &:before {
        position: absolute;
        z-index: 1;
        content: "";
        height: var(--slider-diameter);
        width: var(--slider-diameter);
        left: var(--switch-inner-gap);
        bottom: var(--switch-inner-gap);
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    .switchBg {
      position: absolute;
      color: white;
      height: 100%;
      &.left {
        left: calc(var(--switch-inner-gap) + 1px);
      }
      &.right {
        right: calc(var(--switch-inner-gap) + 4px);
      }
    }
  }
}

.tooltiptext {
  margin-bottom: 5px;
  --tooltip-width: 150px;
  visibility: hidden;
  width: var(--tooltip-width);
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.1rem;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: calc(-1 * var(--tooltip-width) / 2);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8rem;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  *:focus + & {
    visibility: visible;
    opacity: 1;
  }

  *:hover + & {
    visibility: visible;
    opacity: 1;
  }
}