@import "Colours";

.progressBarContainer {
  display: flex;
  background-color: $maple-dark-blue;
  width: 100%;
  color: white;
  padding: 1em;
}

.progressBarInner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progressBarTitle {
  line-height: 1.5;
  font-size: 2.1em;
  text-align: center;
  margin-bottom: 20px;
}

.progressBarUl {
  text-align: center;
  padding: 0;
  margin-bottom: 0;

  p {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.87em;
  }

  li {
    list-style-type: none;
    display: inline-block;
    width: 12%;
    position: relative;
    z-index: 1;
  }
}

.progressBarIconContainer {
  margin: 0 auto;
  display: block;
  height: 2em;
  width: 2em;
  background-color: #b0cbea;
  color: $maple-dark-blue;
  border-radius: 50%;
  font-size: 1.5em;
}

.progressBarUl li {
  span:after {
    content: "";
    background-color: white;
    height: 3px;
    width: 100%;
    display: block;
    position: absolute;
    left: 50%;
    top: 0.9em;
    z-index: -1;
  }
}


ul li {
  &.progressBarStep.active {
    span:after {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .progressBarIconContainer {
      background-color: white;
    }

    ~ li {
      span {
        background-color: white;
        color: rgba(0, 0, 0, 0.65);

        &:after {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  /*Hide last bar*/
  &:last-child span:after {
    display: none;
  }
}

.progressBarIcon {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .progressBarUl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;

    li span:after {
      width: 150%;
    }
  }


  .progressBarInner {
    padding-left: 0;
    padding-right: 0;
  }
}

/*Large Phone size*/
@media screen and (max-width: 500px) {
  .progressBarUl p {
    display: none;
  }
  .progressBarContainer {
    display: none;
  }
}

.baseCardContainer {
  background-color: #fcfcfc;
  border: 1px solid rgb(0, 0, 0, 0.15);
  max-width: 50rem;
  min-width: min(100%, 640px);
}

.purchaseContainer {
  @extend .baseCardContainer;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: 0 auto 5rem auto;
  padding: 30px 20px;
  &.onfido {
    padding-inline: 0;
    background-color: transparent;
    border: none;
    @media screen and (max-width: 479px) {
      padding: 0;
      margin: 0;
      .onfido-sdk-ui-Modal-inner {
        position: static;
        height: calc(100vh - 1.7em - 60px);
      }
    }
  }
  @media screen and (max-width: 425px) {
    padding-inline: 0.75em;
  }

  &.purchaseForm{
    padding: 30px 0px 0px 0px;
  }

  .loaderText {
    font-size: 1.5em;
    font-weight: 500;
    p:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: 500px) {
      text-align: left;
    }
  }

  .loaderSubText {
    opacity: 0.8;
    margin-bottom: 0;
  }

  .loaderCont {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;

    svg[data-icon='check'] {
      color: #3baa35;
    }
    svg[data-icon='check'], .fa-stack {
      vertical-align: baseline;
      height: 96px;
    }

    .fa-stack {
      height: 3em;
      svg:first-child {
        height: 3em;
      }
      svg:last-child {
        color: $maple-dark-blue;
        height: 0.9em;
      }
    }
  }
}

h2.purchaseStepTitle {
  font-size: 1.875rem;
  margin-bottom: 0;
}
h3.purchaseStepTitle {
  margin-bottom: 0;
  margin-top: 30px;
  font-size: 1.5625rem;
}
h4.purchaseStepTitle {
  margin-bottom: 0;
  margin-top: 30px;
  font-size: 1.375rem;
}

.ensureStepContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  p {
    margin-bottom: 0;
    margin-left: 10px;
  }
}
.ensureStepIcon {
  color: $maple-dark-blue-bright;
}

.beginStep {
  .topContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }
  h3:not(:first-child) {
    margin-top: 1.5rem;
  }
}

.onFidoContainer {
  text-align: justify;
  margin: 1.4rem 0;
}

.screeningQuestionContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 90px;

  p {
    max-width: 37.5rem;
    margin-bottom: 0;
  }
}

.screeningPrompt {
  font-size: 1.3rem;
}

.screeningPromptSubtitle {
  font-size: 1.1rem;
  opacity: 0.9;
}

.screeningStep .flexRow {
  justify-content: center;
  margin-top: 20px;
  gap: 80px;
}

.screeningBtn {
  margin: 0;
}

#firstNameLbl, #lastNameLbl {
  p.formatLbl {
    white-space: pre-line;
  }
}

@media (min-width: 768px) {
  #PhoneMaxWidth, #PostalMaxWidth {
    max-width: 12.5rem;
  }
  
  #DobMaxWidth {
    max-width: 16.25rem;
  }
}

.reviewItem {
  margin-bottom: 15px;

  .label {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
  .value {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.purchaseUnauthenticatedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1.5625rem;
  }

  .flexRow {
    column-gap: 3rem;

    .mapleBtn {
      margin-inline: 0;
    }
  }
  @media screen and (max-width: 400px) {
    .flexRow {
      column-gap: 0.5rem;

      .mapleBtn {
        min-width: auto;
        padding-inline: 1em;
      }
    }
  }
}

.paymentHeader{
  padding: 0.5rem 2rem;

  .paymentLineItem {
    display: flex;
    flex-direction: row;
    

    .label {
      font-weight: 600;
    }

    .valContainer {
      text-align: left;
    }
  }
}

.paymentExplanation {
  font-size: 95%;
}

.eTransferCard {
  @extend .baseCardContainer;
  border-radius: 20px;
  margin-bottom: 1.2rem;
  padding: 20px 20px;

  .cardTitle {
    font-size: 1.5rem;
  }

  .eTransferBoldText{
    font-weight: 600;
  }

  .eTransferDisclaimer{
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .specialWarningLink {
    font-size: 0.96rem;
  }

  .eTransferConfirmation {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    padding: 10px 0 5px 0;
  }
  
}

.eTransferSentContainer {
  padding: 0px 20px 30px 20px
}