@import 'Colours';

.legalDocHeaderCont {
  background-color:$maple-dark-blue;
  color: white;
  width: 100%;
  font-weight: 400;
  padding: 40px max(calc(50% - 460px), 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    display: inline;
    margin-bottom: 0;
    font-size: 1.8rem;
  }
  .printBtn {
    margin: 0 -20px 0 20px;
  }
}

.text-container {
  padding: 40px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.text-container a {
  color: $link;
}

.text-container .bottom {
  margin-top: 3em;
}

.text-container .big-h2 {
  font-size: x-large !important;
}

.text-container h2,
.text-container h1{
  text-align: center;
  font-size: large;
  font-weight: 600;
}


@media screen and (max-width: 600px) {
  .legalDocHeaderCont {
    padding: 20px;

    h1 {
      font-size: 1.5rem;
    }
    .printBtn {
      margin: 0 0 0 5px;
    }
  }

  .text-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .legalDocHeaderCont {
    flex-direction: column;
    gap: 8px;

    h1 {
      text-align: center;
    }
    .printBtn {
      width: 96%;
      border-radius: 5px;
      padding: 0.2rem;
    }
  }
}
