@import 'Colours';

.formTitle {
  font-size: 2rem;
  margin-bottom: 20px;
}

.authForm {
  padding: 60px 0;
  background-color: rgb($maple-light-blue, 0.5);
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  .subtitle {
    font-size: 0.925rem;
    opacity: 0.9;
  }

  .formContainer {
    background-color: #fcfcfc;
    border-radius: 30px;
    padding: 20px;
    filter: drop-shadow(0px 10px 10px rgb(0, 0, 0, 0.5));
    border: 1px #ffffff solid;
    margin: 0 auto;
    max-width: 31.25rem;
    
    @media screen and (max-width: 500px) {
      border-radius: 20px;
    }
  }

  .fieldsContainer {
    margin: 20px 30px;
    max-width: 25rem;
    
    @media screen and (max-width: 500px) {
      margin: 20px auto;
    }
  }
}


.formCheckbox {
  margin-top: 20px;
}

.requiredDisclaimer {
  margin-bottom: 5px;
  opacity: 0.8;
  margin-right: 5px;
  font-size: 0.8rem;
}

.formField {
  margin-top: 20px;

  &.amountField>input {
    line-height: 2;
    font-size: 1.3rem;
    text-align: right;
  }

  select, input {
    border: 0.12rem #bcbcbc solid;
    margin-top: 5px;
  }
  
  .react-datepicker__header select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: -moz-calc(.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      border-color: #86b7fe;
      outline: 0;
      -webkit-box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
      box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
    }
  }

  label {
    font-size: 1rem;
    font-weight: 400;
  }

  input.password {
    padding-right: 35px;
  }

  .optionalLbl {
    margin-left: 5px;
    opacity: 0.8;
    font-size: 90%;
  }

  .formatLbl {
    margin-bottom: 0;
    opacity: 0.8;
    font-size: 0.8rem;
  }

  .helpTxt,
  ul.helpTxt li {
    margin-bottom: 0;
    opacity: 0.9;
    font-size: 0.8rem;
    margin-top: 5px;
  }

  .helpTxt li,
  ul.helpTxt {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .password-field-container{
    flex-direction: row;
  }

  .passwordShowToggle {
    float: right;
    margin-top: 8px;
    width: 2.5em;

    z-index: 99;
  }

  .errorTxt {
    display: block !important;
  }
}

.invalidEmailContainer {
  line-height: 8px;
  span {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: $state-danger;
  }
  div {
    display: flex;

    :first-child {
      padding: 0 5px 0 0 !important;
    }

    button {
      font-weight: 600;
      min-height: auto;
      font-size: 11pt;
      padding: 0 5px 0 5px !important;
    }
  }
}

.optionalChkLbl {
  margin-right: 5px;
  opacity: 0.8;
}

.forgotPasswordLink {
  font-size: 80%;
}

.passwordLblDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input.invalid {
  border-color: red;
}

.authBackgroundLeavesWrapper {
  position: absolute;
  width:100%;
}

/*PASSWORD*/
.both-password-field {
  display: flex;
  flex-direction: column;
}

.password-field-container {
  display: flex;
  flex-direction: column;
}

/*PASSWORD HIDE EYE*/
.password-field {
}

@media only screen and (max-width: 767px) {
  .formBackgroundContFull {
    padding-top: 6vh !important;
  }
}

.formInputsRow {
  display: flex;
  flex-direction: row;
  align-items: center;

  .formField:first-child {
    margin-right: 4rem;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;

    .formField:first-child {
      margin-right: 0;
    }
  }
}

.form-check-input:checked, .formCheckbox input:checked {
  background-color: $maple-dark-blue-bright;
  border-color: $maple-dark-blue;
}

.formCheckbox label:hover {
  cursor: pointer;
}

.datepickerContainer {
  .react-datepicker-wrapper {
    width: calc(100% - 48px);
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.datepickerBtn {
  padding: 5px 15px;
  background-color: #f5f5f5;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: $maple-dark-blue;
  border-color: #bcbcbc;
  border-width: 1.5px;
  border-style: solid;
  border-left: none;
  margin-top: 5px;
}

@mixin datepicker-triangle {
  /* fixing triangle on datepicker popup */
  .react-datepicker-popper .react-datepicker__triangle::after {
    left: -18px !important;
    bottom: 1.5px !important;
  }
  .react-datepicker-popper .react-datepicker__triangle::before {
    margin-left: -10px;
    bottom: 1px !important;
  }
}

.linkCardForm {
  @include datepicker-triangle;
}
.purchaseContainer {
  @media screen and (max-width: 768px) {
    @include datepicker-triangle;
  }
}

.react-datepicker-popper[data-placement=bottom-start] .react-datepicker__triangle::after {
  top: 1.5px !important;
}
.react-datepicker-popper[data-placement=bottom-start] .react-datepicker__triangle::before {
  top: 0.5px !important;
}

/* stops weird animation between input and button */
.react-datepicker__tab-loop {
  margin-left: 0 !important;
}

/* hide input field within datepicker component behind our react-final form field */
.react-datepicker-wrapper {
  position: absolute;
  visibility: hidden;
}

.react-datepicker__navigation-icon:before {
  top: 10px !important;
}

.react-datepicker__day--today.react-datepicker__day--selected,
.react-datepicker__day--today.react-datepicker__day--keyboard-selected {
  font-weight: 600 !important;
}

.react-datepicker__day--in-range {
  background-color: $maple-dark-blue !important;
  &:hover {
    background-color: $maple-dark-blue-bright !important;
  }
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background-color: $maple-dark-blue-bright !important;
  &:hover {
    background-color: rgb($maple-dark-blue-bright, 0.8) !important;
  }
}

.react-datepicker__day--in-selecting-range {
  background-color: rgb($maple-dark-blue, 0.7) !important;
}

.react-datepicker__today-button {
  background-color: $maple-dark-blue !important;
  color: white;
  font-family: Frutiger;
  font-weight: 600 !important;
}

.react-datepicker {
  border: none !important;
  font-family: Frutiger !important;

  .react-datepicker__current-month {
    font-weight: 600;
  }

  .react-datepicker__month-container {
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    
    .enableTodayBtn & {
      border-bottom: none;
      border-radius: 0.3rem 0.3rem 0 0;
    }
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select {
    margin: 0 2px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    color: white;
  }
}
