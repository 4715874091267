$maple-red: #EE2D26;
$maple-dark-blue: #181A4B;
$maple-dark-blue-bright: #252774;
$maple-medium-blue: #8FB9E3;
$maple-light-blue: #B0CBEA;

$state-success: #28A745;
$state-warning: #ffd300;
$state-danger: #db3242;

$link: #31349b;
$link-hover: #202266;

$button-primary-active: #3a3c78;

$footer-main: $maple-dark-blue;
$footer-disclaimer: #0F102E;
